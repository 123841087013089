import { imgixURI } from 'src/modules/utils/constants'
import { networkLogos } from 'src/constants/networkLogos'

export const videos = {
  altitude_sports: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/AltitudeSports_1280x720_LP_15s.mp4',
  },
  bally_angels: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_ANGELS_LP_11s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_bluejackets: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_BLUEJACKETS_LP_12s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_blues: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_BLUES_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_braves: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_BRAVES_LP_11s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_brewers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_BREWERS_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_bucks: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Bucks2023_LP_10s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_cardinals: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Cardinals_Partnership_LP_9s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_cavaliers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/CAVS_LP_11s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_clippers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_CLIPPERS_LP_13s_V2.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_coyotes: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_COYOTES_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_ducks: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_DUCKS_LP_14s.mp4',
    staticAnnotation: networkLogos.kcop,
  },
  bally_diamondbacks: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_DIAMONDBACKS_LP_12s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_guardians: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Guardians_LP_10s_16x9.mp4',
    videoMobileUrl: 'https://assets.fubo.tv/marketing/lp/videos/mobile/Guardians_LP_10s_1x1.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_grizzlies: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_GRIZZLIES_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_hawks: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_HAWKS_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_heat: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_HEAT_LP_12s_V2.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_hornets: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_HORNETS_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_hurricanes: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Hurricanes2024_LP_10s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_kings: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_KINGS_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_lightning: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_LIGHTNING_LP_15s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_magic: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_MAGIC_LP_15s_V2.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_marlins: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_MARLINS_LP_13s_V3.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_mavericks: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_MAVERICKS_LP_11s.mp4',
    staticAnnotation: networkLogos.kfaa,
  },
  bally_pacers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_PACERS_LP_11s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_padres: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_PADRES_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_panthers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_PANTHERS_LP_15s_V2.mp4',
    staticAnnotation: networkLogos.scripps_sports,
  },
  bally_pelicans: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_PELICANS_LP_14s.mp4',
    staticAnnotation: networkLogos.gulfcoastsports,
  },
  bally_pistons: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_PISTONS_LP_13s_V2.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_predators: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_PREDATORS_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_rangers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_RANGERS_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_rays: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_RAYS_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_reds: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_REDS_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_redwings: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_REDWINGS_LP_8s_V2.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_royals: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_ROYALS_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_spurs: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_SPURS_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_stars: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_STARS_LP_14s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_suns: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_SUNS_LP_12s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_thunder: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_THUNDER_LP_15s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_tigers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_TIGERS_LP_10s_V3.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_timberwolves: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_TIMBERWOLVES_LP_11s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_twins: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_TWINS_LP_11s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  bally_wild: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BALLY_WILD_LP_13s.mp4',
    staticAnnotation: networkLogos.fanduelsports,
  },
  baltimore_orioles: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Orioles_LP_10s_V2.mp4',
    staticAnnotation: networkLogos.masn,
  },
  bkfc: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/BKFC_2023_LP_14s_2.mp4',
  },
  boston_bruins: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Bruins2023_LP_12s.mp4',
  },
  boston_red_sox: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/RedSox_General_LP_12s.mp4',
  },
  buffalo_sabres: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Sabres2024_1280x720_LP_12s.mp4',
    videoMobileUrl:
      'https://assets.fubo.tv/marketing/lp/videos/mobile/Sabres2024_720x1280_LP_12s.mp4',
  },
  canada_epl: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/CA_EPL_2023_LP_15s.mp4',
  },
  canada_seriea: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/SerieA_Update2022_1280x720_LP_20s.mp4',
  },
  ca_men_in_blazers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/MenInBlazers_LP_10s.mp4',
  },
  cbb: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/CBB_2022_LP_15s.mp4',
    staticAnnotation: {
      logoUrl:
        'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/watch-cbb-on-espn.png?ch=width&auto=format,compress',
      alt: 'Watch College Basketball on ESPN',
      height: 40,
    },
  },
  cfb: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/CFB2021.mp4',
  },
  champions_tour: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/ChampionsCup2024_LP_10s.mp4',
  },
  chicago_sky: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/ChicagoSky_LP_12s.mp4',
  },
  colorado_avalanche: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Avalanche2023_1280x720_LP_8s.mp4',
  },
  concacaf_gold_cup: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/GoldCup_2023_LP_10s.mp4',
  },
  copa_america_us: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/US_CopaAmerica2024_LP_10s.mp4',
    staticAnnotation: networkLogos.foxfs1,
  },
  cricket: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/WillowNetwork_LP_11s.mp4',
  },
  copa_america_latino: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Latino_CopaAmerica2024_LP_10s.mp4',
    staticAnnotation: networkLogos.tudn,
  },
  disney_channel: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/disneychannel.mp4',
  },
  espn: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/ESPND_AmSports_LP_11s_V2.mp4',
    annotations: [
      {
        startSecs: '0',
        endSecs: '1.5',
        alt: '',
        logoUrl: null,
      },
      {
        startSecs: '1.5',
        endSecs: '4.2',
        alt: 'ESPN Deportes',
        logoUrl: networkLogos.espndeportes.logoWhite,
      },
      {
        startSecs: '4.2',
        endSecs: '6.9',
        alt: '',
        logoUrl: null,
      },
      {
        startSecs: '6.9',
        endSecs: '8.55',
        alt: 'ESPN Deportes',
        logoUrl: networkLogos.espndeportes.logoWhite,
      },
      {
        startSecs: '8.55',
        endSecs: '11',
        alt: '',
        logoUrl: null,
      },
    ],
  },
  fifa_women_world_cup: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/FIFA_WWC_LP_10s.mp4',
    staticAnnotation: networkLogos.foxfs1,
  },
  generic_baseball: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Generic_Baseball_1280x720_LP_10s.mp4',
  },
  generic_basketball: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Generic_Basketball_1280x720_LP_10s.mp4',
  },
  generic_basketball_baseball: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Generic_Basketball_Baseball_LP_13s.mp4',
  },
  generic_football: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Generic_Football_1280x720_LP_10s.mp4',
  },
  generic_hockey: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Generic_hockey_1280x720_LP_10s.mp4',
  },
  generic_soccer: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Generic_Soccer_1280x720_LP_10s.mp4',
  },
  generic_sports: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/GenericStock_1280x720_LP_15s.mp4',
  },
  generic_sports_no_football_no_golf: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/GenericNoFootballNoGolf_9s.mp4',
  },
  houston_astros: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Astros_LP_2025_10s.mp4',
    staticAnnotation: networkLogos.spacecity,
  },
  latino: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Latino2022_1280x720_LP_20s.mp4',
    annotations: [
      {
        startSecs: '0',
        endSecs: '3.57',
        alt: 'Liga MX - TUDN',
        logoUrl: imgixURI + 'annotations/univision-ligamx.png',
        height: 80,
      },
      {
        startSecs: '3.57',
        endSecs: '8.15',
        alt: 'UCL - TUDN/Univision',
        logoUrl: imgixURI + 'annotations/univision-ucl.png',
        localImg: true,
        height: 80,
      },
      {
        startSecs: '8.15',
        endSecs: '12.25',
        alt: 'Rosa de Guadelupe - Univision',
        logoUrl: 'https://imgx.fubo.tv/station_logos/univision_m.png',
      },
      {
        startSecs: '12.25',
        endSecs: '14.35',
        alt: 'Univision News',
        logoUrl: 'https://imgx.fubo.tv/station_logos/univision_m.png',
      },
      {
        startSecs: '14.35',
        endSecs: '17.42',
        alt: 'ESPN Deportes',
        logoUrl: 'https://imgx.fubo.tv/station_logos/espn-hd-deportes_m.png',
      },
      {
        startSecs: '17.42',
        endSecs: '20',
        alt: 'FOX',
        logoUrl: 'https://imgx.fubo.tv/station_logos/foxDeportes2021_m.png',
      },
    ],
  },
  leagues_cup: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/LeaguesCup2023_LP_13s.mp4',
  },
  liga_mx: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/LigaMX24_LP_11s.mp4',
    staticAnnotation: {
      logoUrl: imgixURI + 'leagues/ligamx.png',
      alt: 'Liga MX - TUDN',
      height: 80,
    },
  },
  ligue_1: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Ligue1.mp4',
  },
  masn: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/MASN_LP_10s.mp4',
    staticAnnotation: networkLogos.masn,
  },
  mlbtv: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/MLB_2025_LP_16x9_10s.mp4',
    videoMobileUrl: 'https://assets.fubo.tv/marketing/lp/videos/mobile/MLB_2025_LP_1x1_10s.mp4',
    staticAnnotation: {
      logoUrl: 'https://imgx.fubo.tv/station_logos/mlbtv_m.png',
      alt: 'MLB.tv',
      // height: 80,
    },
  },
  nascar: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/NASCAR_2023_LP_15s.mp4',
    staticAnnotation: {
      logoUrl:
        'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/fox_fs1_logos_white.png?ch=width&auto=format,compress',
      alt: 'World Cup 2022 Qatar on Fox and FS1',
      height: 40,
    },
  },
  nba: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/NBA2023_LP_11s_V2.mp4',
    annotations: [
      {
        startSecs: '0',
        endSecs: '0.3',
        alt: '',
        logoUrl: null,
      },
      {
        startSecs: '0.4',
        endSecs: '1.0',
        alt: 'Watch NBA today on ESPN',
        logoUrl:
          'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/watch-nba-today-on-espn.png?ch=width&auto=format,compress',
      },
      {
        startSecs: '1.0',
        endSecs: '2.6',
        alt: '',
        logoUrl: null,
      },
      {
        startSecs: '2.6',
        endSecs: '4.6',
        alt: 'Watch NBA today on ESPN',
        logoUrl:
          'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/watch-nba-today-on-espn.png?ch=width&auto=format,compress',
      },
      {
        startSecs: '4.6',
        endSecs: '11',
        alt: '',
        logoUrl: null,
      },
    ],
  },
  nbatv: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/nbatv2018.mp4',
  },
  news: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/News_1280x720_LP_23s.mp4',
    annotations: [
      {
        startSecs: '0',
        endSecs: '8.02',
        alt: 'CNN',
        logoUrl: 'https://imgx.fubo.tv/station_logos/cbs_m.png',
      },
      {
        startSecs: '8.02',
        endSecs: '12.23',
        alt: 'NBC',
        logoUrl: 'https://imgx.fubo.tv/station_logos/nbc_m.png',
      },
      {
        startSecs: '12.23',
        endSecs: '23.0',
        alt: 'FOX News',
        logoUrl: 'https://imgx.fubo.tv/station_logos/fox_news_m.png',
      },
    ],
  },
  new_york_yankees: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Yankees_LP_10s.mp4',
    staticAnnotation: networkLogos.yesnetwork,
  },
  nfl: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/NFL_2023Update_1280x720_LP_16s.mp4',
    annotations: [
      {
        startSecs: '0',
        endSecs: '6.2',
        alt: 'Fox',
        logoUrl: 'https://imgx.fubo.tv/station_logos/fox_m.png',
      },
      {
        startSecs: '6.2',
        endSecs: '16.0',
        alt: 'ESPN',
        logoUrl: 'https://imgx.fubo.tv/station_logos/espn_m.png',
      },
    ],
  },
  nflespn: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/NFL_ESPN_2022_LP_15s.mp4',
    staticAnnotation: networkLogos.espn,
  },
  nhl: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/NHL_2022_LP_30s.mp4',
    staticAnnotation: {
      logoUrl: 'https://imgx.fubo.tv/station_logos/espn_m.png',
      alt: 'ESPN',
    },
  },
  oscars: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/TheOscars_2023_ABC_LP_Full.mp4',
    staticAnnotation: {
      logoUrl: 'https://imgx.fubo.tv/station_logos/ABC_m.png',
      alt: 'ABC',
      height: 80,
    },
  },
  pff_nfl_draft: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/PFF_NFL2023Draft_LP_13s.mp4',
  },
  pittsburgh_penguins: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Penguins2023_1280x720_LP_8s.mp4',
    staticAnnotation: networkLogos.sportsnetpittsburgh,
  },
  seasonal_ca: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/CA_Spring2025_16x9.mp4',
    videoMobileUrl: 'https://assets.fubo.tv/marketing/lp/videos/mobile/CA_Spring2025_1x1.mp4',
    annotations: [
      {
        startSecs: '0',
        endSecs: '5.2',
        alt: networkLogos.fsn.alt,
        logoUrl: networkLogos.fsn.logoWhite,
        height: 60,
      },
      {
        startSecs: '5.2',
        endSecs: '10.2',
        alt: networkLogos.mlbnetwork.alt,
        logoUrl: networkLogos.mlbnetwork.logoWhite,
        height: 70,
      },
      {
        startSecs: '10.2',
        endSecs: '15',
        alt: networkLogos.willow.alt,
        logoUrl: networkLogos.willow.logoWhite,
        height: 40,
      },
    ],
  },
  seasonal_us: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/US_SMV3_Spring25_15s_16x9.mp4',
    videoMobileUrl:
      'https://assets.fubo.tv/marketing/lp/videos/mobile/US_SMV3_Spring25_15s_1x1.mp4',
    annotations: [
      {
        startSecs: '0',
        endSecs: '2.3',
        alt: networkLogos.mlbnetwork.alt,
        logoUrl: networkLogos.mlbnetwork.logoWhite,
        height: networkLogos.mlbnetwork.height,
      },
      {
        startSecs: '2.3',
        endSecs: '3.2',
        alt: 'The Voice | NBC',
        logoUrl: imgixURI + 'annotations/smv_spring_25/The_Voice_NBC.png',
        height: 30,
      },
      {
        startSecs: '3.2',
        endSecs: '4.75',
        alt: 'RHOA | Bravo',
        logoUrl: imgixURI + 'annotations/smv_spring_25/RHOA-Bravo-w.png',
        height: 50,
      },
      {
        startSecs: '4.75',
        endSecs: '6.2',
        alt: networkLogos.espn.alt,
        logoUrl: networkLogos.espn.logoWhite,
        height: networkLogos.espn.height,
      },
      {
        startSecs: '6.2',
        endSecs: '8',
        alt: 'Suits | NBC',
        logoUrl: imgixURI + 'annotations/smv_spring_25/Suits_LA_NBC_w.png',
        height: 30,
      },
      {
        startSecs: '8',
        endSecs: '9.7',
        alt: networkLogos.espn.alt,
        logoUrl: networkLogos.espn.logoWhite,
        height: networkLogos.espn.height,
      },
      {
        startSecs: '9.7',
        endSecs: '13',
        alt: 'National Geographic',
        logoUrl: imgixURI + 'annotations/smv_spring_25/national_geo_logo_w.png',
      },
      {
        startSecs: '13',
        endSecs: '15',
        alt: networkLogos.nbc.alt,
        logoUrl: networkLogos.nbc.logoWhite,
        height: networkLogos.nbc.height,
      },
    ],
  },
  seattle_mariners: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Mariners_LP_10s_V2.mp4',
    staticAnnotation: networkLogos.rootsports,
  },
  soccer: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/Soccer2022_1280x720_LP_24s_V4.mp4',
    annotations: [
      {
        startSecs: '0.0',
        endSecs: '5.5',
        alt: 'UEFA Nations League',
        logoUrl:
          'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/uefa_nations_league.png',
      },
      {
        startSecs: '5.51',
        endSecs: '10.5',
        alt: 'EPL on NBC',
        logoUrl: imgixURI + 'annotations/soccer/03_EPL.png',
      },
      {
        startSecs: '10.51',
        endSecs: '13.5',
        alt: 'Liga MX',
        logoUrl: imgixURI + 'annotations/soccer/04_Liga_MX.png',
      },
      {
        startSecs: '13.51',
        endSecs: '19.0',
        alt: "FIFA Men's World Cup",
        logoUrl: imgixURI + 'annotations/soccer/05_FIFAM.png',
      },
      {
        startSecs: '19.01',
        endSecs: '26.0',
        alt: "FIFA Women's World Cup",
        logoUrl: imgixURI + 'annotations/soccer/06_FIFAW.png',
      },
    ],
  },
  texas_rangers: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/TxRangers_LP_10s.mp4',
  },
  ucl: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/UCL_2024_LP_10s.mp4',
  },
  ufl: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/UFL2024_LP_10s.mp4',
  },
  super_bowl: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/LA_SuperBowlVIX_LP_10s.mp4',
    staticAnnotation: networkLogos.univision,
  },
  uefa_euro_qualifiers: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/EURO2024_Qualifiers_LP_13s.mp4',
  },
  uefa_nations_league: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/NationsLeague_1280x720_17s_LP_V2.mp4',
  },
  uefa_euros: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Euro2024_LP_10s.mp4',
  },
  us_open: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/USOpen2024_LP_12s.mp4',
    videoMobileUrl:
      'https://assets.fubo.tv/marketing/lp/videos/mobile/USOpen2024_Mobile_LP_12s.mp4',
  },
  washington_nationals: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/Nationals_LP_10s.mp4',
    staticAnnotation: networkLogos.masn,
  },

  womens_basketball: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/WNCAA_Basketball_LP_9s.mp4',
  },
  worldcup: {
    videoUrl:
      'https://assets.fubo.tv/marketing/lp/videos/compressed/EN_WorldCup2022_1280x720_LP_15s.mp4',
    staticAnnotation: {
      logoUrl:
        'https://assets-imgx.fubo.tv/marketing/lp/images/annotations/world_cup_qatar_2022_fox_fs1.png?h=100&ch=width&auto=format,compress',
      alt: 'World Cup 2022 Qatar on Fox and FS1',
      height: 80,
    },
  },
  xfl: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/XFL_LP_14s.mp4',
  },
  yes_network: {
    videoUrl: 'https://assets.fubo.tv/marketing/lp/videos/compressed/YES_LP_10s_V2.mp4',
    staticAnnotation: networkLogos.yesnetwork,
  },
}
