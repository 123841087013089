import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { addOnCodes, networkLogos, ratePlanCodes } from 'src/constants'
import { leagueIds, sportIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

export default (props) => {
  return (
    <StyledContainer>
      <USDefaultTemplate
        location={props.location}
        lang="en"
        market="us"
        video={'https://assets-imgx.fubo.tv/marketing/lp/videos/compressed/IPL_LP_1280x720_10s.mp4'}
        videoMobile={
          'https://assets-imgx.fubo.tv/marketing/lp/videos/mobile/IPL_LP_1080x1080_10s.mp4'
        }
        title="Watch Indian Premier League live"
        subtitle="Stream every IPL cricket match on Willow TV"
        staticAnnotation={networkLogos.willow_by_cricbuzz}
        networkSet={'zeeFamily'}
        networkHeadline="Stream hit movies and shows in Hindi, Punjabi and more"
        platformHeadline={'Watch live cricket from around the world'}
        sportId={sportIds.Cricket}
        matchtickerSportId={sportIds.Cricket}
        matchtickerLeagueName="Cricket"
        matchtickerLeagueId={leagueIds.IPL}
        caRedirectUrl={'/ca/ipl/'}
        faqListOverride={faqListOverride}
        hasZeeFamilyPlanCard={true}
        addonCode={addOnCodes.cricket}
        offerCode={ratePlanCodes.zee_family_mo}
        offerBanner={<>✔ Watch cricket with Fubo and save!</>}
        platformImage={
          'https://assets-imgx.fubo.tv/marketing/lp/images/devices/cricket-device-poster.png'
        }
        disableFreeTrial={true}
        pageDescription={
          'Stream live TV with Zee TV, Zee Cinema, Zee Bollywood and more. Plus Willow TV, GolTV and others. Unlimited Cloud DVR included. No contract, cancel anytime.'
        }
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 680px;
  }
`

const faqListOverride = [
  {
    question: 'What is the Willow TV & Zee Family plan from Fubo?',
    answer:
      'The Willow TV & Zee Family plan from Fubo includes Willow and Willow Xtra for 24/7 cricket matches, news and more. Plus 18 more channels of top Indian and South Asian content in English, Hindi and other regional languages. Enjoy top shows, Bollywood hits, classic movies and more.',
  },

  {
    question: 'Can I stream the Mumbai Indians vs. Chennai Super Kings cricket match with Fubo?',
    answer:
      'Yes, the match between Mumbai Indians and Chennai Super Kings, and other top cricket rivalries, is available with Fubo via Willow TV, allowing you to catch all the live action.',
  },
  {
    question:
      'How can I find the Kolkata Knight Riders vs. Royal Challengers Bangalore match on Fubo?',
    answer:
      'You can find the Kolkata Knight Riders vs. Royal Challengers Bangalore match on Fubo by navigating to Willow TV in your channel lineup, where the match will be streamed live.',
  },
  {
    question: 'Can I watch IPL replays and highlights on Fubo?',
    answer:
      'Yes, Fubo offers IPL replays, highlights, commentary and expert analysis on Willow TV, Willow Sports and Willow Xtra.',
  },
  {
    question: 'Can I watch Virat Kohli and Royal Challengers Bangalore live with Fubo?',
    answer:
      'Yes, you can watch Virat Kohli play in the IPL with Royal Challengers Bangalore on Willow TV with Fubo.',
  },
  {
    question: 'Will I be able to watch MS Dhoni and Chennai Super Kings live with Fubo?',
    answer:
      'Yes, you can watch MS Dhoni play in the IPL with Chennai Super Kings  on Willow TV with Fubo.',
  },
  {
    question: 'How do I access live IPL matches on Fubo?',
    answer:
      "To access live IPL events on Fubo, simply tune into Willow TV, where all the matches are streamed live, ensuring you don't miss any action.",
  },
  {
    question: 'Are there any shows related to IPL on Fubo?',
    answer:
      'Yes, Fubo features various shows related to cricket and IPL, including pre-match and post-match analysis, available through Willow TV to keep you updated on all the action.',
  },
  {
    question: 'Is the Indian Premier League available for streaming with Fubo?',
    answer:
      'Yes, the IPL is available for streaming with Fubo on Willow TV. You can watch it live or save every match to your the included Unlimited Cloud DVR with one click.',
  },
  {
    question: 'Can I watch classic Indian films with Fubo?',
    answer:
      'Yes, classic Indian films are available on Fubo through Zee Cinema, where you can enjoy a selection of timeless Bollywood hits and regional classics.',
  },
  {
    question: 'Can I watch Hindi movies with Fubo?',
    answer:
      'Absolutely! Hindi movies are available with Fubo on Zee Cinema, Zee TV, Zee Bollywood and more. You will find a range of classic and contemporary films in the Hindi language.',
  },
  {
    question: 'Can I watch cricket highlights with Fubo?',
    answer:
      'Yes, you can watch cricket highlights and match replays with Fubo. Just be sure to subscribe to a plan or add-on that includes Willow TV in its channel lineup.',
  },
  {
    question: 'How can I find specific Indian movies with Fubo?',
    answer:
      'To find specific Indian movies on Fubo, simply use the search function and enter the movie title or browse through the Zee Cinema channel for the latest offerings.',
  },
  {
    question: 'Can I record cricket matches with Fubo?',
    answer:
      'Yes, all Fubo plans include Unlimited DVR so you can record cricket matches, TV shows, movies and more, and watch them when you want, on your TV or other devices.',
  },
  {
    question: 'Does Fubo have DVR?',
    answer:
      'Yes, every Fubo plan includes unlimited Cloud DVR space at no extra charge. That means you can record all your favorite teams, shows, movies and more. Since our DVR is cloud-based, that means you can access all of your DVR recordings from all of your devices — with no extra equipment or installation required. So if you hit record on your smart TV, you can watch the recording on your phone.',
  },
  {
    question: 'How many devices can I watch Fubo on at once?',
    answer:
      'Most Fubo plans allow you to stream on up to 10 devices at once when on your home internet connection. So some people watch on their phones, others can watch on their computers, and you can watch on your big-screen smart TV — all at the same time. Plus, you can stream on up to three devices at once when on the go. With our Latino plan, you can watch on up to two devices at the same time.',
  },
  {
    question: 'Which devices can I stream from with Fubo?',
    answer: (
      <>
        {' '}
        With Fubo, you can stream on all popular connected TVs, smart phones and computers.
        Including Apple TV, Roku, Samsung Smart TV and Amazon Fire TV; Mac and PC computers; Apple
        and Android phones and tablets; and more. View the list of all supported devices & browsers{' '}
        <a
          href="https://support.fubo.tv/hc/en-us/articles/7120445443085-What-supported-devices-can-I-watch-Fubo-on"
          target="_blank"
        >
          here
        </a>
        .
      </>
    ),
  },
]
