export const sportIds = {
  Basketball: 59,
  Football: 111,
  Cycling: 247,
  Golf: 117,
  'Horse Racing': 248,
  Baseball: 58,
  Hockey: 122,
  'Mixed martial arts': 231,
  'Auto racing': 53,
  Soccer: 199,
  Tennis: 215,
  Boxing: 72,
  Cricket: 88,
}

export const leagueIds = {
  "Men's NCAA Basketball": 191260,
  'NCAA Football': 191261,
  '150th Kentucky Derby': 26703121,
  MLB: 191273,
  NBA: 191276,
  NFL: 191277,
  NHL: 191278,
  'Professional Fighters League': 15471095,
  NASCAR: 815400,
  'Formula 1': 691060,
  'Ligue 1': 3606627,
  'Liga MX': 9208870,
  'CONCACAF Nations League': 17567599,
  'La Liga': 3609994,
  CONMEBOL: 21865682,
  MLS: 191274,
  'US Open': 27552666,
  WNBA: 191289,
  BKFC: 10081926,
  'PGA Golf Tour': 845788,
  IPL: 3606596,
  SA20: 23818606,
  ICC: 29290904,
  'Premier League': 3605402,
  'TGL Golf': 26049265,
  'NASCAR Cup Series': 17799520,
  'NASCAR Racing': 815400,
  'Liv Golf': 22296060,
  'Masters Golf': 28817988,
}

export const leagueGroupIds = {
  golf: [
    leagueIds['TGL Golf'],
    leagueIds['PGA Golf Tour'],
    leagueIds['Liv Golf'],
    leagueIds['Masters Golf'],
  ],
  nascar: [leagueIds['NASCAR Cup Series'], leagueIds['NASCAR Racing']],
  nba: [leagueIds['NBA'], leagueIds['WNBA']],
  cricket: [leagueIds['IPL'], leagueIds['SA20'], leagueIds['ICC']],
}

export const teamIds = {
  'Utah Hockey Club': 65676,
  'Alabama Crimson Tide': 65,
  'Clemson Tigers': 99,
  'Colorado Buffaloes': 102,
  'Florida Gators': 121,
  'Georgia Bulldogs': 131,
  'LSU Tigers': 167,
  'Michigan Wolverines': 171,
  'Ohio State Buckeyes': 214,
  'Oklahoma Sooners': 215,
  'Oregon Ducks': 217,
  'Penn State Nittany Lions': 219,
  'Florida State Seminoles': 125,
  'USC Trojans': 274,
  'Texas Longhorns': 258,
  'Tennessee Volunteers': 255,
  'Notre Dame Fighting Irish': 212,
  'Pittsburgh Pirates': 22,
  'Seattle Mariners': 25,
  'White Sox': 6,
  'Boston Red Sox': 4,
  'Arizona Diamondbacks': 1,
  'Atlanta Braves': 2,
  'Baltimore Orioles': 3,
  'Cincinnati Reds': 7,
  'Cleveland Guardians': 23385,
  'Colorado Rockies': 9,
  'Detroit Tigers': 10,
  'Kansas City Royals': 13,
  'Los Angeles Angels': 16396,
  'Miami Marlins': 6075,
  'Milwaukee Brewers': 16,
  'Minnesota Twins': 17,
  'New York Yankees': 19,
  'San Diego Padres': 23,
  'St Louis Cardinals': 26,
  'Tampa Bay Rays': 27,
  'Texas Rangers': 28,
  'Washington Nationals': 30,
  'Houston Rockets': 409,
  'Boston Celtics': 401,
  'New York Knicks': 419,
  '76ERS': 421,
  'Denver Nuggets': 406,
  'Golden State Warriors': 408,
  'Sacramento Kings': 424,
  'Atlanta Hawks': 400,
  'Charlotte Hornets': 8210,
  'Cleveland Cavaliers': 404,
  'Dallas Mavericks': 405,
  'Detroit Pistons': 407,
  'Indiana Pacers': 410,
  'Los Angeles Clippers': 411,
  'Memphis Grizzlies': 413,
  'Miami Heat': 414,
  'Milwaukee Bucks': 415,
  'Minnesota Timberwolves': 416,
  'New Orleans Pelicans': 7766,
  'Oklahoma City Thunder': 426,
  'Orlando Magic': 420,
  'Phoenix Suns': 422,
  'San Antonio Spurs': 425,
  'Arizona Cardinals': 31,
  'Atlanta Falcons': 32,
  'Baltimore Ravens': 33,
  'Buffalo Bills': 34,
  'Carolina Panthers': 35,
  'Chicago Bears': 36,
  'Cincinnati Bengals': 37,
  'Cleveland Browns': 38,
  'Dallas Cowboys': 39,
  'Denver Broncos': 40,
  'Detroit Lions': 41,
  'Green Bay Packers': 42,
  'Houston Texans': 43,
  'Indianapolis Colts': 44,
  'Jacksonville Jaguars': 45,
  'Kansas City Chiefs': 46,
  'Las Vegas Raiders': 20783,
  'Los Angeles Chargers': 14215,
  'Los Angeles Rams': 9690,
  'Miami Dolphins': 47,
  'Minnesota Vikings': 48,
  'New England Patriots': 49,
  'New Orleans Saints': 50,
  'New York Giants': 51,
  'New York Jets': 52,
  'Philadelphia Eagles': 54,
  'Pittsburgh Steelers': 55,
  'San Francisco 49ers': 57,
  'Seattle Seahawks': 58,
  'Tampa Bay Buccaneers': 60,
  'Tennessee Titans': 61,
  'Washington Commanders': 23731,
  'Boston Bruins': 432,
  'Pittsburgh Penguins': 453,
  'Seattle Kraken': 21209,
  'Anaheim Ducks': 430,
  'Arizona Coyotes': 8211,
  'Carolina Hurricanes': 435,
  'Columbus Blue Jackets': 438,
  'Dallas Stars': 439,
  'Detroit Red Wings': 440,
  'Florida Panthers': 442,
  'Los Angeles Kings': 443,
  'Minnesota Wild': 444,
  'Nashville Predators': 446,
  'St Louis Blues': 455,
  'Tampa Bay Lightning': 456,
  'Chicago Sky': 475,
  'Houston Astros': 12,
  'Portland Trail Blaizers': 423,
  'Colorado Avalanche': 437,
  'Buffalo Sabres': 433,
  'Utah Jazz': 428,
  'Chicago Bulls': 403,
}
