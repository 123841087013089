import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectProChannels, selectEliteChannels } from 'src/modules/reducers/products/selectors'
import { defaultCTA } from 'src/modules/utils/constants'
import { networkLogos, ratePlanCodes } from 'src/constants'
import PlanCardWithAddon from '../PlanCardWithAddon'

const willowCricketPromoImg =
  'https://assets-imgx.fubo.tv/marketing/lp/images/card/ca_og_cricket_01.jpg'

const cardListItems = [
  { available: true, text: 'Willow and Willow Xtra' },
  {
    available: true,
    text: '24/7 cricket matches, news & more',
  },
  { available: true, text: 'Every IPL and ICC match' },
  { available: true, text: '18 South Asian channels' },
  { available: true, text: 'Unlimited Cloud DVR' },
]

const cardAddOnInfo = {
  title: 'International Sports Plus add-on includes:',
  logos: [
    'https://assets-imgx.fubo.tv/marketing/lp/images/networks/willow-logo-v3.png',
    'https://assets-imgx.fubo.tv/marketing/lp/images/networks/willow-extra-logo-v3.png',
    networkLogos.tyc.logoWhite,
    networkLogos.goltv.logoWhite,
  ],
}

const cardNetworkLogos = [
  {
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/willow-logo-v3.png',
    alt: networkLogos.willow.alt,
    width: 100,
    height: 40,
  },
  {
    logo: 'https://assets-imgx.fubo.tv/marketing/lp/images/networks/willow-extra-logo-v3.png',
    alt: networkLogos.willow_extra.alt,
    width: 151,
    height: 30,
  },
  { alt: '+ MORE' },
]

const zeeFamilyPlanData = (channels, lang, flag = false) => {
  const dataEn = {
    zeeFamilyQuarterly: {
      promoImg: willowCricketPromoImg,
      offerCode: ratePlanCodes.zee_family_qt,
      ctaText: defaultCTA.us.noTrial.text,
      plan: 'pro',
      title: 'Willow TV & Zee Family',
      subtitle: 'Quarterly',
      price: 45,
      discountedPrice: 30,
      asterisk: true,
      banner: 'Save 33% with Quarterly',
      footnote:
        '*Offer valid for the first 3 months. Renews at $45 every 3 months. Additional taxes and fees may apply. Prices subject to change. Cancel anytime.',
      listItems: cardListItems,
      addOnInfo: cardAddOnInfo,
      networkLogos: cardNetworkLogos,
    },
    zeeFamilyMonthly: {
      promoImg: willowCricketPromoImg,
      offerCode: ratePlanCodes.zee_family_mo,
      ctaText: defaultCTA.us.noTrial.text,
      plan: 'pro',
      title: 'Willow TV & Zee Family',
      subtitle: 'Monthly',
      price: 15,
      discountedPrice: 9.99,
      asterisk: false,
      footnote:
        'Offer valid for the first month. Renews at $15/mo. Additional taxes and fees may apply. Prices subject to change. Cancel anytime.',
      listItems: cardListItems,
      addOnInfo: cardAddOnInfo,
      networkLogos: cardNetworkLogos,
    },
  }

  // if (flag) {
  //   dataEn.zeeFamilyAnnual = {
  //     promoImg: willowCricketPromoImg,
  //     offerCode: ratePlanCodes.zee_family_yr,
  //     ctaText: defaultCTA.us.noTrial.text,
  //     plan: 'pro',
  //     title: 'Zee Family Annual',
  //     subtitle: '+ International Sports Plus',
  //     price: 11.98,
  //     discountedPrice: 9.99,
  //     asterisk: true,
  //     footnote:
  //       '*Charged for the first 12 mos at $119.88 (including $11.88 for the International Sports Plus add-on). Additional taxes and fees may apply. Prices subject to change. Cancel anytime.',
  //     listItems: cardListItems,
  //     addOnInfo: cardAddOnInfo,
  //     networkLogos: cardNetworkLogos,
  //   }
  // }

  return dataEn
}

const ZeeFamilyPlanCard = ({ lang, ctaText }) => {
  const proChannels = useSelector(selectProChannels)
  const eliteChannels = useSelector(selectEliteChannels)

  const channels = {
    pro: proChannels,
    elite: eliteChannels,
  }
  const planData = zeeFamilyPlanData(channels, lang)

  const title = 'Which plan is right for you?'

  const footnote = ''
  return (
    <StyledBundleSection>
      <div id="select-plan"></div>
      <div className="section-header">
        <h2>{title}</h2>
      </div>
      <div className="section-content">
        {planData &&
          Object.entries(planData).map(([plan, data], index) => {
            return (
              <PlanCardWithAddon
                key={index}
                promoImg={data.promoImg}
                offerCode={data.offerCode}
                plan={data.plan}
                title={data.title}
                subtitle={data.subtitle}
                price={data.price}
                discountedPrice={data.discountedPrice}
                tagline={data.tagline}
                listItems={data.listItems}
                ctaText={ctaText || data.ctaText}
                channels={channels[plan]}
                banner={data.banner}
                footnote={data.footnote}
                asterisk={data.asterisk}
                networkLogos={data.networkLogos}
                // addOnInfo={data.addOnInfo}
              />
            )
          })}
      </div>
      <div className="section-footnote">{footnote}</div>
    </StyledBundleSection>
  )
}

export default ZeeFamilyPlanCard

const StyledBundleSection = styled.section`
  color: #fff;
  padding: 60px 20px 60px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #19273c;
  background-image: null;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .inline-block {
    display: inline-block;
  }

  @media (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }

  #select-plan {
    position: absolute;
    top: -80px;
  }

  .desktop-line-break {
    display: block;
    @media (max-width: 576px) {
      display: inline;
    }
  }

  .section-header {
    padding: 0 0 30px 0;
    margin-bottom: 35px;

    @media screen and (max-width: 1019px) {
      padding: 0;
      ${'' /* margin-bottom: 0; */}
    }

    h2 {
      color: rgb(240, 241, 242);
      font-size: 40px;
      font-weight: 700;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      letter-spacing: 1px;
      line-height: 44px;

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0px;
      line-height: 23px;
      margin-top: 20px;
    }
  }

  .section-tabs {
    width: 100%;
    max-width: 500px;
    .plan-tabs {
      ${'' /* border: 3px solid #fa4616; */}
      ${'' /* border-radius: 10px; */}
      overflow: hidden;
      margin-bottom: 30px;
      display: flex;
      width: 100%;
      max-width: 500px;
      align-items: flex-end;
      > * {
        flex: 1 0 0;
      }
      .tab {
        position: relative;
      }
      .savings {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .tab:first-child {
        button {
          border-radius: 10px 0 0 10px;
        }
      }
      .tab:last-child {
        button {
          border-radius: 0 10px 10px 0;
        }
      }
      button {
        width: 100%;
        ${'' /* Reset Button CSS */}
        border: none;
        margin: 0;
        padding: 15px 0;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        cursor: pointer;
        background: #ffffff;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }

        &.active {
          ${'' /* background: #e6e6e6; */}
          background: #000;
          color: #fff;
        }

        .tab-content {
          ${'' /* padding: 15px 18px; */}
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          -webkit-letter-spacing: -0.17px;
          -moz-letter-spacing: -0.17px;
          -ms-letter-spacing: -0.17px;
          letter-spacing: -0.17px;
          line-height: 20px;
          text-transform: uppercase;
          @media (max-width: 600px) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1400px;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    @media (min-width: 1020px) {
      align-items: stretch;
      flex-direction: row;
      gap: 49px;
      margin-bottom: 50px;
    }
  }

  .section-footnote {
    color: #c2c2c2;
    text-align: center;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 22.44px */
    max-width: 530px;
  }
`
