// import React from 'react'
// import USTemplateDefault from 'src/components/template/us'
// import { videos } from 'src/constants/videos'
// import { leagueGroupIds, sportIds } from 'src/constants/personalizationIds'
// import styled from 'styled-components'
// export default props => (
// <StyledContainer>
//   <USTemplateDefault
//     location={props.location}
//     lang="en"
//     market="us"
//     addonCode="premier-intl-sports-plus"
//     video={videos.cricket.videoUrl}
//     title="Watch live cricket matches"
//     subtitle="CATCH THE BIGGEST EVENTS OF THE YEAR"
//     tagline="Stream them live from your TV & other devices"
//     sportId={sportIds.Cricket}
//     matchtickerSportId={sportIds.Cricket}
//     matchtickerLeagueName="Cricket"
//     matchtickerLeagueId={leagueGroupIds.cricket}
//     caRedirectUrl={'/ca/cricket/'}
//     additionalFAQs={additionalFAQs}
//     platformImage={'https://assets-imgx.fubo.tv/marketing/lp/images/devices/cricket-device-poster.png'}
//   />
// </StyledContainer>
// )

// const StyledContainer = styled.div`
//   .content-container .hero-content {
//     max-width: 600px;
//   }
// `

// const additionalFAQs = [
//   {
//     question: 'Can I watch live cricket matches on Fubo?',
//     answer:
//       'Yes, you can watch live cricket matches on Fubo through Willow, which provides extensive coverage of cricket events.',
//   },
//   {
//     question: 'How can I find specific cricket matches on Fubo?',
//     answer:
//       'To find specific cricket matches on Fubo, simply use the search feature and type in the name of the match or team. You can also browse through the Willow channel for a schedule of upcoming games.',
//   },
//   {
//     question: 'Does Fubo offer cricket commentary and analysis shows?',
//     answer:
//       'Yes, Fubo offers cricket commentary and analysis shows through Willow, providing in-depth insights and expert opinions on ongoing matches.',
//   },
//   {
//     question: 'Can I watch recorded cricket matches on Fubo?',
//     answer:
//       "Yes, you can watch recorded cricket matches on Fubo if they are available on Willow's replay feature. Check the channel for any past matches you might have missed.",
//   },
//   {
//     question: 'What cricket events are covered live on Fubo?',
//     answer:
//       'Fubo covers a variety of live cricket events, including international matches, domestic tournaments, and special series, all available through the Willow channel.',
//   },
//   {
//     question: 'How do I access cricket highlights on Fubo?',
//     answer:
//       'You can access cricket highlights on Fubo by navigating to the Willow channel. Highlights of recent matches are often featured and can be watched on-demand.',
//   },
//   {
//     question: 'Are there any cricket shows available on Fubo?',
//     answer:
//       'Yes, Fubo features various cricket shows that cover analysis, player interviews, and match previews, all available through the Willow channel.',
//   },
//   {
//     question: 'Can I watch cricket tournaments on Fubo?',
//     answer:
//       'Absolutely! You can watch major cricket tournaments on Fubo, including the ICC events and various T20 leagues, all broadcasted live on Willow.',
//   },
// ]

import React from 'react'
import USDefaultTemplate from 'src/components/template/us'
import { addOnCodes, ratePlanCodes } from 'src/constants'
import { videos } from 'src/constants/videos'
import { leagueGroupIds, sportIds } from 'src/constants/personalizationIds'
import styled from 'styled-components'

export default (props) => {
  return (
    <StyledContainer>
      <USDefaultTemplate
        location={props.location}
        lang="en"
        market="us"
        video={videos.cricket.videoUrl}
        title="Watch live cricket matches"
        subtitle="STREAM EVERY ICC AND IPL MATCH ON WILLOW TV"
        networkSet={'zeeFamily'}
        networkHeadline="Stream hit movies and shows in Hindi, Punjabi and more"
        platformHeadline={'Watch live cricket from around the world'}
        sportId={sportIds.Cricket}
        matchtickerSportId={sportIds.Cricket}
        matchtickerLeagueName="Cricket"
        matchtickerLeagueId={leagueGroupIds.cricket}
        caRedirectUrl={'/ca/cricket/'}
        faqListOverride={faqListOverride}
        hasZeeFamilyPlanCard={true}
        addonCode={addOnCodes.cricket}
        offerCode={ratePlanCodes.zee_family_mo}
        offerBanner={<>✔ Watch cricket with Fubo and save!</>}
        platformImage={
          'https://assets-imgx.fubo.tv/marketing/lp/images/devices/cricket-device-poster.png'
        }
        disableFreeTrial={true}
        pageDescription={
          'Stream live TV with Zee TV, Zee Cinema, Zee Bollywood and more. Plus Willow TV, GolTV and others. Unlimited Cloud DVR included. No contract, cancel anytime.'
        }
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  .content-container .hero-content {
    max-width: 600px;
  }
`

const faqListOverride = [
  {
    question: 'What is the Willow TV & Zee Family plan from Fubo?',
    answer:
      'The Willow TV & Zee Family plan from Fubo includes Willow and Willow Xtra for 24/7 cricket matches, news and more. Plus 18 more channels of top Indian and South Asian content in English, Hindi and other regional languages. Enjoy top shows, Bollywood hits, classic movies and more.',
  },
  {
    question: 'Can I watch Hindi movies with Fubo?',
    answer:
      'Absolutely! Hindi movies are available with Fubo on Zee Cinema, Zee TV, Zee Bollywood and more. You will find a range of classic and contemporary films in the Hindi language.',
  },
  {
    question: 'Can I watch the India vs England T20 matches with Fubo?',
    answer:
      'Yes, you can watch the India vs England T20 matches on Fubo through Willow TV, which provides live coverage of cricket events.',
  },
  {
    question: 'How can I find the ICC Champions Trophy 2025 matches with Fubo?',
    answer:
      'You can find the ICC Champions Trophy 2025 matches on Fubo by searching for its name or by searching for Willow TV in the channel guide, where live coverage of the tournament will be available.',
  },
  {
    question: 'Is the Indian Premier League available for streaming with Fubo?',
    answer:
      'Yes, the IPL is available for streaming with Fubo on Willow TV. You can watch it live or save every match to your the included Unlimited Cloud DVR with one click.',
  },
  {
    question: 'Can I watch classic Indian films with Fubo?',
    answer:
      'Yes, classic Indian films are available on Fubo through Zee Cinema, where you can enjoy a selection of timeless Bollywood hits and regional classics.',
  },
  {
    question: 'Can I watch the ICC World Test Championship Final with Fubo?',
    answer:
      'Yes, the ICC World Test Championship Final will be available on Fubo through Willow TV, which will provide live coverage of the event.',
  },
  {
    question: "Where can I watch the 2025 Women's Cricket World Cup with Fubo?",
    answer:
      "You can watch the 2025 Women's Cricket World Cup with Fubo on Willow TV, which will broadcast the tournament live.",
  },
  {
    question: 'Can I watch cricket highlights with Fubo?',
    answer:
      'Yes, you can watch cricket highlights and match replays with Fubo. Just be sure to subscribe to a plan or add-on that includes Willow TV in its channel lineup.',
  },
  {
    question: 'How can I find specific Indian movies with Fubo?',
    answer:
      'To find specific Indian movies on Fubo, simply use the search function and enter the movie title or browse through the Zee Cinema channel for the latest offerings.',
  },
  {
    question: 'Can I record cricket matches with Fubo?',
    answer:
      'Yes, all Fubo plans include Unlimited DVR so you can record cricket matches, TV shows, movies and more, and watch them when you want, on your TV or other devices.',
  },
  {
    question: 'Does Fubo have DVR?',
    answer:
      'Yes, every Fubo plan includes unlimited Cloud DVR space at no extra charge. That means you can record all your favorite teams, shows, movies and more. Since our DVR is cloud-based, that means you can access all of your DVR recordings from all of your devices — with no extra equipment or installation required. So if you hit record on your smart TV, you can watch the recording on your phone.',
  },
  {
    question: 'How many devices can I watch Fubo on at once?',
    answer:
      'Most Fubo plans allow you to stream on up to 10 devices at once when on your home internet connection. So some people watch on their phones, others can watch on their computers, and you can watch on your big-screen smart TV — all at the same time. Plus, you can stream on up to three devices at once when on the go. With our Latino plan, you can watch on up to two devices at the same time.',
  },
  {
    question: 'Which devices can I stream from with Fubo?',
    answer: (
      <>
        {' '}
        With Fubo, you can stream on all popular connected TVs, smart phones and computers.
        Including Apple TV, Roku, Samsung Smart TV and Amazon Fire TV; Mac and PC computers; Apple
        and Android phones and tablets; and more. View the list of all supported devices & browsers{' '}
        <a
          href="https://support.fubo.tv/hc/en-us/articles/7120445443085-What-supported-devices-can-I-watch-Fubo-on"
          target="_blank"
        >
          here
        </a>
        .
      </>
    ),
  },
]
